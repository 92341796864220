.skill-section {
  padding: 40px 15px;
  background-color: #000000; /* Black background */
  color: #ffffff; /* White text color for contrast */
}

.skill-section h2 {
  text-align: center;
  margin-bottom: 30px;
  font-size: 1.5rem;
  font-weight: bold;
  color: #ffffff;
}

.skill-category {
  margin-bottom: 20px;
}

.skill-category-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #333333;
  color: #ffffff;
  border: 1px solid #444444;
  border-radius: 5px;
  cursor: pointer;
}

.skill-category-title {
  font-weight: bold;
}

.toggle-icon {
  font-size: 18px;
}

.skill-category-content {
  display: flex;
  flex-wrap: wrap; /* Allows skills to wrap on smaller screens */
  gap: 10px; /* Space between skill items */
  padding: 10px;
}

.skill-item {
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  padding: 5px 10px;
  background-color: #444444;
  border-radius: 5px;
}

.skill-icon {
  font-size: 24px;
  margin-right: 10px;
}

.skill-name {
  font-size: 14px;
  color: #ffffff;
}

/* Specific icon styles */
.html-icon { color: #e44b23; }
.css-icon { color: #1572b6; }
.js-icon { color: #f7e018; }
.python-icon { color: #306998; }
.php-icon { color: #8993be; }
.react-icon { color: #61dafb; }
.node-icon { color: #68a063; }
.angular-icon { color: #dd0031; }
